import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

export default function Datenschutz() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Datenschutzerklärung</h1>
        
        <div className="mt-10 max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">1. Name und Kontaktdaten des Verantwortlichen</h2>
          <p className="mt-6">
            floatcraft UG (haftungsbeschränkt) <br />
            Klingestraße 13C <br />
            01159 Dresden <br />
            Telefon: 017675444136 <br />
            E-Mail: info@floatcraft.de <br />
            Geschäftsführer: Linus Haddad
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck ihrer Verwendung</h2>
          
          <h3 className="mt-8 text-xl font-semibold tracking-tight text-gray-900">a. Beim Besuch der Website</h3>
          <p className="mt-6">
            Beim Aufrufen unserer Website www.studentenathome.de werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sogenannten Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatischen Löschung gespeichert:
          </p>
          <ul role="list" className="mt-8 max-w-xl space-y-4 text-gray-600">
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>IP-Adresse des anfragenden Rechners</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Datum und Uhrzeit des Zugriffs</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Name und URL der abgerufenen Datei</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Website, von der aus der Zugriff erfolgt (Referrer-URL)</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers</span>
            </li>
          </ul>
          <p className="mt-8">
            Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
          </p>
          <ul role="list" className="mt-8 max-w-xl space-y-4 text-gray-600">
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Gewährleistung einer komfortablen Nutzung unserer Website</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Auswertung der Systemsicherheit und -stabilität</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Zu weiteren administrativen Zwecken</span>
            </li>
          </ul>

          <p className="mt-8">
            Darüber hinaus setzen wir beim Besuch unserer Website Cookies und ähnliche Technologien ein. Weitere Informationen hierzu finden Sie in unserer <a href="#" className="text-indigo-600 hover:text-indigo-900">Cookie-Richtlinie</a>.
          </p>

          <h3 className="mt-8 text-xl font-semibold tracking-tight text-gray-900">b. Bei der Nutzung unseres technischen Supports</h3>
          <p className="mt-6">
            Wenn Sie unseren technischen Support in Anspruch nehmen, erheben wir folgende Informationen:
          </p>
          <ul role="list" className="mt-8 max-w-xl space-y-4 text-gray-600">
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Anrede, Vorname, Nachname</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Eine gültige E-Mail-Adresse</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Telefonnummer (Festnetz und/oder Mobilfunk)</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Informationen zur technischen Ausstattung und dem Problem</span>
            </li>
          </ul>
          <p className="mt-8">
            Die Datenerhebung erfolgt,
          </p>
          <ul role="list" className="mt-8 max-w-xl space-y-4 text-gray-600">
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Um Sie als unseren Kunden identifizieren zu können</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Um Sie angemessen beraten und betreuen zu können</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Zur Korrespondenz mit Ihnen</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Zur Rechnungsstellung</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Zur Abwicklung von eventuellen Haftungsansprüchen sowie der Geltendmachung etwaiger Ansprüche gegen Sie</span>
            </li>
          </ul>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">3. Weitergabe von Daten</h2>
          <p className="mt-6">
            Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.
          </p>
          <p className="mt-8">
            Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
          </p>
          <ul role="list" className="mt-8 max-w-xl space-y-4 text-gray-600">
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist</span>
            </li>
          </ul>
          <p className="mt-8">
            In bestimmten Fällen kann es erforderlich sein, Ihre personenbezogenen Daten in Länder außerhalb des Europäischen Wirtschaftsraums (EWR) zu übermitteln. Dies erfolgt nur unter Einhaltung der geltenden Datenschutzvorschriften, z.B. durch Verwendung von EU-Standardvertragsklauseln.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">4. Ihre Rechte</h2>
          <p className="mt-6">
            Sie haben das Recht:
          </p>
          <ul role="list" className="mt-8 max-w-xl space-y-4 text-gray-600">
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen.</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen.</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen.</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen.</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen.</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              <span>Gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren.</span>
            </li>
          </ul>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">5. Widerspruchsrecht</h2>
          <p className="mt-6">
            Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben. Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an info@floatcraft.de.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">6. Datensicherheit</h2>
          <p className="mt-6">
            Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256-Bit-Verschlüsselung. Falls Ihr Browser keine 256-Bit-Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftritts verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.
          </p>
          <p className="mt-8">
            Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">7. Aktualität und Änderung dieser Datenschutzerklärung</h2>
          <p className="mt-6">
            Diese Datenschutzerklärung ist aktuell gültig und hat den Stand [15.08.2024].
          </p>
          <p className="mt-8">
            Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter www.studentenathome.de von Ihnen abgerufen und ausgedruckt werden.
          </p>
        </div>
      </div>
    </div>
  )
}
