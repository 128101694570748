import { CheckIcon } from '@heroicons/react/20/solid'

const tiers = [
  {
    name: 'Einsteiger',
    id: 'tier-personal',
    href: '#',
    priceMonthly: '30 € / 30 Minuten',
    description: "Perfekt, wenn Sie gezielte Unterstützung für kleinere technische Aufgaben benötigen.",
    features: [
      'Einrichtung von Druckern und mobilen Geräten',
      'Netzwerkkonfiguration und -optimierung',
      'Grundlegende Fehlerbehebungen',
      'Unterstützung bei Windows, Mac OS und Linux',
      'Freundlicher E-Mail-Support',
    ],
    featured: true,
  },
  {
    name: 'Premium',
    id: 'tier-team',
    href: '#',
    priceMonthly: 'Preis auf Anfrage',
    description: 'Ideal für umfassenden Support und maßgeschneiderte Lösungen für Ihr Unternehmen oder komplexe Projekte.',
    features: ['Individuelle technische Lösungen für Ihre Geräte', 'Umfassende Schulungen und persönliche Beratung', 'Erweiterter Support für komplexe Systeme', 'Vor-Ort-Service und flexible Anfahrtszeiten'],
    featured: false,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl" aria-hidden="true">
        <div
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
        <h2 className="text-base font-semibold leading-7 text-indigo-600">Preise</h2>
        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        Der richtige Plan für Sie – maßgeschneiderter Techniksupport
        </p>
      </div>
      <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
      Egal, ob Sie nur gelegentliche Unterstützung benötigen oder umfassenden technischen Service wünschen – wir haben den passenden Plan für Sie. 
      </p>
      <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
        {tiers.map((tier, tierIdx) => (
          <div
            key={tier.id}
            className={classNames(
              tier.featured ? 'relative bg-white shadow-2xl' : 'bg-white/60 sm:mx-8 lg:mx-0',
              tier.featured
                ? ''
                : tierIdx === 0
                ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl'
                : 'sm:rounded-t-none lg:rounded-tr-3xl lg:rounded-bl-none',
              'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10'
            )}
          >
            <h3 id={tier.id} className="text-base font-semibold leading-7 text-indigo-600">
              {tier.name}
            </h3>
            <p className="mt-4 flex items-baseline gap-x-2">
              <span className="text-5xl font-bold tracking-tight text-gray-800">{tier.priceMonthly}</span>
              <span className="text-base text-gray-500"></span>
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">{tier.description}</p>
            <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 sm:mt-10">
              {tier.features.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  {feature}
                </li>
              ))}
            </ul>
            <a
              href={tier.href}
              aria-describedby={tier.id}
              className={classNames(
                tier.featured
                  ? 'bg-indigo-600 text-white shadow hover:bg-indigo-500'
                  : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                'mt-8 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-10'
              )}
            >
              Jetzt starten
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
