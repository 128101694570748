import React from 'react';
import Services from '../components/Service/Services';
//import Pricings2 from '../components/Service/Princings2';
import Pricings3 from '../components/Service/Pricings3';

function Service() {
    return (
        <div>
           <Services />
           <Pricings3 />
        </div>
    );
}

export default Service;