import { CheckIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Netzwerkkonfiguration',
    description: 'Wir passen Ihr Netzwerk maßgeschneidert an, um optimale Leistung und Sicherheit zu gewährleisten.',
  },
  { name: 'Individuelle Konfigurationen', description: 'Ihre Geräte werden passgenau eingerichtet, um den wachsenden Anforderungen der digitalen Welt gerecht zu werden. So sind Sie für zukünftige Herausforderungen bestens vorbereitet.' },
  {
    name: 'Technische Fehlerbehebungen',
    description: 'Wir lösen Ihre technischen Probleme schnell und effizient, damit Sie sich wieder auf das Wesentliche konzentrieren können.',
  },
  {
    name: ' Unterstützung bei Drucker- und Handyeinrichtungen',
    description: 'Egal, ob es um die Einrichtung eines neuen Druckers oder die Konfiguration Ihres Smartphones geht, wir stehen Ihnen mit unserem Fachwissen zur Seite.',
  },
  { name: 'Umfassender Support', description: 'Ob Windows, Mac OS oder Linux – wir sind mit allen gängigen Betriebssystemen vertraut und bieten Ihnen umfassende Unterstützung, egal welches System Sie nutzen.' },
  { name: 'Datenmanagement und -sicherung', description: 'Wir sorgen dafür, dass Ihre wichtigen Daten sicher gespeichert und effizient verwaltet werden, damit Sie jederzeit darauf zugreifen können.' },
  {
    name: 'Schnelle Reaktionszeiten',
    description: 'Wir wissen, wie wichtig es ist, dass technische Probleme schnell behoben werden. Unser Team ist darauf spezialisiert, Ihnen innerhalb kürzester Zeit zu helfen.',
  },
  {
    name: 'Beratung und Schulung',
    description: 'Neben technischer Unterstützung bieten wir auch Schulungen an, damit Sie verstehen, wie Sie Ihre Geräte optimal nutzen und schützen können.',
  },
]

export default function Services() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Alles was Sie brauchen</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Unsere  Schlüsselstrategien</p>
            <p className="mt-6 text-base leading-7 text-gray-600">
            bieten Ihnen maßgeschneiderte Lösungen für alle technischen Herausforderungen. 
            Egal ob es um die Konfiguration von Netzwerken, individuelle Einstellungen Ihrer Geräte oder schnelle Fehlerbehebungen geht – wir stehen Ihnen zur Seite.
            </p>
          </div>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="font-semibold text-gray-900">
                  <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                  {feature.name}
                </dt>
                <dd className="mt-2">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
