import React from 'react';
//import HeroSectionAboutUs from '../components/HeroSectionAboutUs';
import HeroSectionAboutUs2 from '../components/HeroSectionAboutUs2';
import OurValues from '../components/OurValues';
import JoinTeam from '../components/JoinTeam';


function AboutUs() {
    return (
        <div>
            <HeroSectionAboutUs2 />
            <OurValues />
            <JoinTeam />
        </div>
    );
}

export default AboutUs;