import React from 'react';

// Example values array (you can replace this with actual data or props)
const values = [
  { name: 'Zuverlässigkeit', description: 'Wir sind da, wenn Sie uns brauchen, und bieten schnelle und zuverlässige Lösungen.' },
  { name: 'Kompetenz', description: 'Unsere Studenten sind bestens ausgebildet und halten sich ständig auf dem neuesten Stand der Technik.' },
  { name: 'Empathie', description: 'Wir wissen, dass jeder eine andere Beziehung zur Technik hat – und wir sind hier, um Ihnen ohne Fachjargon und auf Augenhöhe zu helfen.' }
];

function OurValues() {
  return (
    <>
      {/* Image section */}
      <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
        <img
          src="https://images.unsplash.com/photo-1529156069898-49953e39b3ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
          alt=""
          className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
        />
      </div>

      {/* Values section */}
      <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Unsere Mission</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Bei StudentenAtHome ist es unser Ziel, Ihnen die besten technischen Lösungen direkt zu Ihnen nach Hause zu bringen. 
          Unser Team besteht aus engagierten Informatik-Studenten, die nicht nur über das neueste Wissen verfügen, sondern auch eine Leidenschaft dafür haben, Menschen bei technischen Herausforderungen zu unterstützen. 
          Wir glauben daran, dass Technik für jeden zugänglich sein sollte – und genau dafür sind wir hier.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {values.map((value) => (
            <div key={value.name}>
              <dt className="font-semibold text-gray-900">{value.name}</dt>
              <dd className="mt-1 text-gray-600">{value.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </>
  );
}

export default OurValues;
