import { CheckCircleIcon, InformationCircleIcon } from "@heroicons/react/20/solid";

export default function Impressum() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Impressum
        </h1>
        
        <div className="mt-10 max-w-2xl">
          <p>
            <strong>Floatcraft UG (haftungsbeschränkt)</strong> <br />
            Klingestraße 13C <br />
            01159 Dresden
          </p>
          <p className="mt-4">
            Vertreten durch: <br />
            Linus Haddad (Geschäftsführer)
          </p>
          <p className="mt-4">
            Kontakt: <br />
            Telefon: +49 17675 444136 <br />
            E-Mail: info@studentenathome.de
          </p>
          <p className="mt-4">
            Handelsregister: <br />
            Amtsgericht Dresden <br />
            HRB 44670
          </p>
          <p className="mt-4">
            Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: <br />
            DE364287642
          </p>
          <p className="mt-4">
            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: <br />
            Linus Haddad <br />
            Klingestraße 13C <br />
            01159 Dresden
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            Haftungsausschluss
          </h2>
          <p className="mt-6">
            <strong>Haftung für Inhalte</strong> <br />
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>
          <p className="mt-8">
            <strong>Urheberrecht</strong> <br />
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            Streitschlichtung
          </h2>
          <p className="mt-6">
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{" "}
            <a href="https://ec.europa.eu/consumers/odr" className="text-indigo-600 hover:text-indigo-900">
              https://ec.europa.eu/consumers/odr
            </a>. Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>
          
        </div>
      </div>
    </div>
  );
}
