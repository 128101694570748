import React from 'react';
import Contactus from '../components/ContactUs/Contactus';
import FeatureSection from '../components/ContactUs/FeatureSection';

function ContactUs() {
    return (
        <div>
           <FeatureSection />
            <Contactus />
        </div>
    );
}

export default ContactUs;