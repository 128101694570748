//import React from 'react';

import HeroSectionWhite from "../components/HeroSectionWhite";
import FeatureSection from "../components/FeatureSection";
import FeatureSection2 from "../components/FearuteSection2";
import Newletter from "../components/Newletter";
function Home() {
  return (
    <div>
      <HeroSectionWhite />
      <FeatureSection />
      <FeatureSection2 />
      <Newletter />
    </div>
  );
}

export default Home;
